import { DateHelper } from '@loadsmart/loadsmart-ui'

import { ISO_DATE_FORMAT } from 'components/Timeline/consts'
import type { ShipmentsListFilters } from 'services/shipments'
import { cleanEmptyValues } from 'utils/cleanEmptyValues'
import { MODES } from 'utils/constants'
import { formatDateTimeWithTimezone } from 'utils/dateUtils'
import { plural } from 'utils/strings'
import { isTransportationModeOneOf } from 'utils/transportationMode'

import { getMilitaryTime } from '../Details/AppointmentCard/utils'
import type {
  Shipment,
  ShipmentListMetadata,
  ShipmentListOverviewFilters,
  Stop,
} from './types'

const fieldMapper: Partial<
  Record<keyof ShipmentsListFilters, (value: any) => any>
> = {
  carrier: (carriers?: ShipmentsListFilters['carrier']) =>
    carriers?.map(({ uuid }) => uuid),
}

export function filtersMapper(filters?: ShipmentsListFilters) {
  if (!filters) {
    return {}
  }

  return Object.keys(filters).reduce((acc, curr) => {
    const key = curr as keyof ShipmentsListFilters
    let value = filters[key]

    if (fieldMapper[key]) {
      value = fieldMapper[key](value)
    }

    // parse string query params to boolean values
    if (value === 'true' || value === 'false') {
      return {
        ...acc,
        [curr]: value === 'true',
      }
    }

    return {
      ...acc,
      [key]: value,
    }
  }, {})
}

export function formatRequestSchedulingIgnoredShipments(
  shipmentsIgnored: number
) {
  const shipmentsIgnoredFormatted = plural('shipment', shipmentsIgnored)

  return `${shipmentsIgnored} ${shipmentsIgnoredFormatted} will be ignored due to no pending appointment`
}

export function requestSchedulingMapper(
  shipments: string | string[],
  filters: ShipmentsListFilters | undefined
) {
  const payload = Array.isArray(shipments)
    ? { shipment_uuids: shipments }
    : { all: true, filters: cleanEmptyValues(filters ?? {}) }

  return payload
}

export const getBigNumbersTotal = (metadata?: ShipmentListMetadata) => {
  if (!metadata) {
    return 0
  }

  const statuses: ShipmentListOverviewFilters[] = [
    'new',
    'quoting',
    'tendering',
    'booked',
    'ongoing',
    'delivered',
  ]

  return (Object.keys(metadata.overview) as ShipmentListOverviewFilters[])
    .filter((key) => statuses.includes(key))
    .reduce((total, key) => {
      return total + metadata.overview[key].total
    }, 0)
}

export const getTrackingBigNumbersTotal = (metadata?: ShipmentListMetadata) => {
  if (!metadata) {
    return 0
  }

  return metadata.overview.ongoing.total
}

export const extractResults = (data?: PaginatedResult<Shipment>) => {
  return data?.results || []
}

export const isLateArrival = (mode: string, stop?: Stop) => {
  if (isTransportationModeOneOf(mode, [MODES.VLTL, MODES.LTL])) {
    return false
  }

  if (!stop?.appointment) {
    return false
  }

  const { appointment, check_in } = stop
  const { scheduled_date, scheduled_time } = appointment

  if (
    scheduled_date?.end &&
    scheduled_date.timezone &&
    scheduled_time?.end &&
    check_in?.done_at
  ) {
    const appointmentEnd = formatDateTimeWithTimezone(
      `${scheduled_date.end} ${getMilitaryTime(scheduled_time.end)}`,
      ISO_DATE_FORMAT,
      scheduled_date.timezone
    )

    const appointmentDate = DateHelper(appointmentEnd, { normalize: false })
    const checkInDate = DateHelper(check_in?.done_at, { normalize: false })

    return checkInDate.is('>', appointmentDate, 'second')
  }

  return false
}

export function getCurrentStop(stops: Stop[]) {
  return stops.find(
    (stop) => stop.check_in?.done_at && !stop.check_out?.done_at
  )
}
