import { DateFormatHelper, DateHelper } from '@loadsmart/loadsmart-ui'
import {
  Button,
  Dropdown,
  Header,
  Icon,
  Layout,
  Text,
  Tooltip,
} from '@loadsmart/miranda-react'
import { useFulfillmentHistoryContext } from 'fulfillments/components/FulfillmentHistory'
import { FulfillmentStatusTag } from 'fulfillments/components/FulfillmentStatusTag'
import { canCreateShipment } from 'fulfillments/domain/Fulfillment'
import {
  formatFulfillmentSource,
  formatFulfillmentStop,
} from 'fulfillments/fulfillment-utils'
import { useCallback } from 'react'
import { generatePath, useHistory } from 'react-router-dom'

import { AppRoutes } from 'router/AppRoutes'

import { useViewFulfillmentContext } from '../ViewFulfillmentContext'

export function ViewFulfillmentPageHeader() {
  const history = useHistory()
  const {
    fulfillment,
    isErrorLoadingFulfillment,
    isFulfillmentSupplier,
    isLoadingFulfillment,
    mileage,
  } = useViewFulfillmentContext()
  const { openDrawer } = useFulfillmentHistoryContext()

  function handleBack() {
    history.goBack()
  }

  const onShowHistoryClick = useCallback(() => {
    openDrawer(fulfillment?.uuid)
  }, [fulfillment?.uuid, openDrawer])

  const onPlanAsShipmentClick = useCallback(() => {
    const params = {
      fulfillments: fulfillment!.uuid,
      stop_type_0: 'pickup',
      stop_facility_0: fulfillment!.pickup_facility.uuid,
      stop_type_1: 'delivery',
      stop_facility_1: fulfillment!.delivery_facility.uuid,
    }
    history.push(
      generatePath(
        `${AppRoutes.NewShipment}?${new URLSearchParams(params).toString()}`
      )
    )
  }, [history, fulfillment])

  if (isErrorLoadingFulfillment || isLoadingFulfillment || !fulfillment) {
    return (
      <Header>
        <Header.BackButton onClick={handleBack} />
        <Header.Content>
          <Header.Title>
            <Layout.Group gap="none">Fulfillment</Layout.Group>
          </Header.Title>
        </Header.Content>
      </Header>
    )
  }

  const dateFormatter = DateFormatHelper('MMM D')
  return (
    <Header>
      <Header.BackButton onClick={handleBack} />
      <Header.Content>
        <Layout.Group justify="space-between" style={{ width: '100%' }}>
          <Layout.Stack gap="spacing-2">
            <Layout.Stack gap="spacing-1">
              <Layout.Group align="center" gap="spacing-6">
                <Layout.Group align="center" gap="spacing-1">
                  <Text variant="body-lg-bold">{`Fulfillment ${fulfillment?.ref_number}`}</Text>
                  <Text variant="body-lg-bold">&bull;</Text>
                  <Text variant="body-lg-bold">
                    {formatFulfillmentStop(fulfillment.pickup_facility)}
                  </Text>
                  <Text variant="body-lg-bold">&rarr;</Text>
                  <Text variant="body-lg-bold">
                    {formatFulfillmentStop(fulfillment.delivery_facility)}
                  </Text>
                </Layout.Group>
                <FulfillmentStatusTag
                  fulfillment={fulfillment}
                  size="default"
                />
              </Layout.Group>

              <Layout.Group align="center" gap="spacing-2">
                <Text variant="heading-sm-bold" color="color-text-tertiary">
                  {dateFormatter.format(
                    DateHelper(fulfillment.pickup_ready_date)
                  )}
                </Text>

                <Text variant="heading-sm-bold">&rarr;</Text>

                <Text variant="heading-sm-bold" color="color-text-tertiary">
                  {dateFormatter.format(DateHelper(fulfillment.delivery_date))}
                </Text>

                {mileage && (
                  <>
                    <Text color="color-text-tertiary" variant="heading-sm-bold">
                      &bull;
                    </Text>
                    <Text
                      variant="heading-sm-bold"
                      color="color-text-tertiary"
                    >{`${mileage?.total_miles} miles`}</Text>
                  </>
                )}
              </Layout.Group>
            </Layout.Stack>

            <Layout.Group align="center" gap="spacing-2">
              <Text variant="body-sm" color="color-text-placeholder">
                {formatFulfillmentSource(fulfillment)}
              </Text>

              {!isFulfillmentSupplier && (
                <Tooltip message="Show history" placement="top" trigger="hover">
                  <Button
                    type="button"
                    variant="icon-secondary"
                    size="small"
                    aria-label="show history"
                    onClick={onShowHistoryClick}
                  >
                    <Icon name="clock" size="14px" />
                  </Button>
                </Tooltip>
              )}
            </Layout.Group>
          </Layout.Stack>

          <Layout.Stack gap="spacing-2" align="flex-end">
            <Layout.Group>
              <Dropdown position="fixed">
                <Tooltip message="More actions" placement="top">
                  <Button
                    variant="icon-secondary"
                    aria-label="open fulfillment actions menu"
                    id="fulfillment_actions_trigger"
                    aria-haspopup="true"
                    tabIndex={0}
                  >
                    <Icon name="dots-vertical" />
                  </Button>
                </Tooltip>
                <Dropdown.Menu>
                  <Dropdown.Item
                    onClick={onPlanAsShipmentClick}
                    disabled={!canCreateShipment(fulfillment)}
                  >
                    <Layout.Stack gap="spacing-0-5">
                      Plan as shipment...
                      <Text
                        variant="body-sm"
                        color={
                          !canCreateShipment(fulfillment)
                            ? 'color-text-disabled'
                            : 'color-text-tertiary'
                        }
                      >
                        Create a shipment including this fulfillment
                      </Text>
                    </Layout.Stack>
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </Layout.Group>
          </Layout.Stack>
        </Layout.Group>
      </Header.Content>
    </Header>
  )
}
