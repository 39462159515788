import type { SelectOptionProps } from '@loadsmart/loadsmart-ui'
import { Select, HighlightMatch, useSelect } from '@loadsmart/loadsmart-ui'
import type { SelectDatasource } from '@loadsmart/loadsmart-ui/dist/components/Select/Select.types'
import { Text, Layout } from '@loadsmart/miranda-react'

import OpenDockIcon from 'assets/imgs/opendock-icon.png'
import { mapFiltersToURLSearchParams } from 'screens/Shipper/FacilityManagement/useFacilities'
import { getFacilities } from 'services/facilities'
import type { FacilityDetailsV2, FacilityV2 } from 'services/facilities'
import { joinValidStrings } from 'utils/strings'

export const FACILITY_ADAPTER = {
  getKey: (facility: Pick<FacilityV2, 'uuid'>) => facility.uuid,
  getLabel: (facility: Pick<FacilityV2, 'name' | 'address'>) => {
    return facility.name || facility.address
  },
}

export function facilityToFacilityDetails(
  facility: FacilityV2
): FacilityDetailsV2 {
  return {
    ...facility,
    contacts: [],
    hours_of_operation: [],
    notes: [],
    accessorials: [],
  }
}

export function facilityDetailsToFacility(
  facility: FacilityDetailsV2
): FacilityV2 {
  return {
    uuid: facility.uuid,
    name: facility.name,
    address: facility.address,
    address_details: facility.address_details,
    city: facility.city,
    state: facility.state,
    zipcode: facility.zipcode,
    country: facility.country,
    warehouse_uuid: facility.warehouse_uuid,
    contacts: facility.contacts.length,
    external_id: facility.external_id,
  }
}

export function buildFacilitiesDatasource() {
  return (): SelectDatasource<FacilityV2> => {
    return {
      type: 'facility',
      adapter: FACILITY_ADAPTER,
      async fetch({ query }) {
        const { results: facilities } = await getFacilities({
          params: mapFiltersToURLSearchParams({
            search: query,
            sort: 'name',
          }),
        })

        return facilities
      },
    }
  }
}

export function CustomFacilityOption({ value }: SelectOptionProps) {
  const select = useSelect()
  const option = select.getOption(value) as FacilityV2
  const query = select.query

  const { warehouse_uuid, address, address_details } = option
  const label = FACILITY_ADAPTER.getLabel(option)

  return (
    <Select.Option value={value}>
      <Layout.Stack gap="spacing-0-5">
        <Layout.Group gap="spacing-1" align="center">
          <Text variant="body-md-bold">
            <HighlightMatch text={label} match={query} data-testid={value} />
          </Text>
          &nbsp;
          {warehouse_uuid && (
            <img src={OpenDockIcon} height={12} alt="OpenDock" />
          )}
        </Layout.Group>
        <Text variant="body-sm">
          {joinValidStrings([address, address_details], ', ')}
        </Text>
      </Layout.Stack>
    </Select.Option>
  )
}
